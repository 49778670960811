// 遗产地和各级文保单位的对应关系
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'DWMC',
                label: '文保单位名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'GBLX',
                minWidth: 105,
                label: '文保单位类型'
            }, {
                prop: 'GBJB',
                label: '公布级别',
                minWidth: 105,
            }],
        },
        // 操作列
        columnsBtn: {
            buttonData: []
        },
    },
    // snSearch: {
    // selectData: [{
    //     label: '遗产要素类型',
    //     clearable: true,
    //     placeholder: "请选择遗产要素类型",
    //     list: [{
    //         ID: '1',
    //         NAME: '建/构筑物'
    //     }, {
    //         ID: '2',
    //         NAME: '遗址/墓葬'
    //     }, {
    //         ID: '3',
    //         NAME: '洞窟/龛'
    //     }, {
    //         ID: '4',
    //         NAME: '造像/雕塑/碑刻/题刻/壁画/彩画'
    //     }, {
    //         ID: '5',
    //         NAME: '山体'
    //     }, {
    //         ID: '6',
    //         NAME: '水体'
    //     }, {
    //         ID: '7',
    //         NAME: '植被'
    //     }, {
    //         ID: '8',
    //         NAME: '街区'
    //     }, {
    //         ID: '9',
    //         NAME: '其他'
    //     }],
    //     optionLabel: 'NAME',
    //     optionValue: 'ID',
    //     value: 'YCYSLX',
    //     operateType: 'search',
    //     isShow: true
    // }]
    // }
};
export default selfData;